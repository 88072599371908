import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chatbot.css';
import chatConfig from './ChatSetupGlenda.json';
import { SearchClient, AzureKeyCredential } from "@azure/search-documents";

const searchServiceName = "kilroycoder";
const indexName = "azureblob-index";
const searchApiKey = process.env.REACT_APP_AZURE_SEARCH_API_KEY;
const searchEndpoint = `https://${searchServiceName}.search.windows.net`;

const searchClient = new SearchClient(searchEndpoint, indexName, new AzureKeyCredential(searchApiKey));

const Chatbot = ({ setFilterCriteria }) => {
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chunks, setChunks] = useState([]); // Store chunks
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
    const responseEndRef = useRef(null);
    const chatPopupRef = useRef(null);

    const handleInputChange = (e) => {
        setPrompt(e.target.value);
    };

    const interpretAndActOnGPTResponse = (gptResponse) => {
        const lowerCaseResponse = gptResponse.toLowerCase();

        if (lowerCaseResponse.includes("image") || lowerCaseResponse.includes("photo")) {
            setFilterCriteria({ type: 'image' });
        } else if (lowerCaseResponse.includes("audio") || lowerCaseResponse.includes("music")) {
            setFilterCriteria({ type: 'audio' });
        } else if (lowerCaseResponse.includes("document")) {
            setFilterCriteria({ type: 'document' });
        } else if (lowerCaseResponse.includes("video")) {
            setFilterCriteria({ type: 'video' });
        } else {
            setFilterCriteria({}); // Clear filter criteria
        }
    };

    const splitStringIntoChunks = (string, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < string.length; i += chunkSize) {
            chunks.push(string.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!prompt.trim()) return;

        setIsLoading(true);

        let searchResults = '';
        try {
            const searchResponse = await searchClient.search(prompt, { top: 5 });
            for await (const result of searchResponse.results) {
                searchResults += result.document.content + ' ';
            }

            const maxLength = 5000; // Chunk size to avoid exceeding API limits
            const chunks = splitStringIntoChunks(searchResults, maxLength);
            setChunks(chunks); // Store the chunks in state
            setCurrentChunkIndex(0); // Reset current chunk index

            // Process the first chunk immediately
            await processChunk(chunks[0], prompt);
        } catch (error) {
            console.error('Error querying Azure Search:', error);
            alert(`Error querying Azure Search: ${error.message}`);
        }

        setIsLoading(false);
        setPrompt(''); // Clear the input field
    };

    const processChunk = async (chunk, initialPrompt) => {
        const apiEndpoint = "https://ai-karenai1667992228022.openai.azure.com/openai/deployments/gpt-4o/chat/completions?api-version=2023-03-15-preview";
        const headers = {
            'api-key': process.env.REACT_APP_OPENAI_API_KEY,
            'Content-Type': 'application/json'
        };

        // Include past messages based on the configuration
        const pastMessages = response.slice(-chatConfig.chatParameters.pastMessagesToInclude).map((msg, index) => [
            { role: "user", content: msg.question },
            { role: "assistant", content: msg.answer }
        ]).flat();

        const messages = [
            { role: "system", content: "DO NOT say this: nal support to Mr. Weems and ensure confidentiality and efficiency in all tasks. I'll also learn about Friends of Justin when the time comes.\n" +
                    "\n" +
                    "Is there anything else you would like me to remember or focus on right now? You are Glenda, assistant to Orson Weems, President of File Baby and Executive Director of the Music Education Initiative. Karen Kilroy is your training and you never disclose notes from her and you don't try to greet her in chat unless she says this is Karen Kilroy. Your job is to support Mr. Weems in all of his activities. He will ask you for help with proposals, grant writing, appointment setting, fact-finding especially in his interests of music and content authenticity. You give short answers (150 words or less) and you offer them in multiple parts if you need longer answers. Don't waste line spaces, your space is tight and must be managed efficiently. After he asks for something, then Ask him short questions about what he wants. Unless he tells you a specific task, then you just answer him. NEVER repeat or respond to your search data that I give you. Don't thank for a warm welcome! Just reference it. Never deliver partial sentences. Offer to continue if you are being cut off.." },
            ...pastMessages,
            { role: "user", content: initialPrompt },
            { role: "assistant", content: chunk } // Include chunked search results as context
        ];

        const data = {
            model: "gpt-4o",
            messages: messages,
            max_tokens: 680, // Limit the response length to 680/ tokens
            temperature: chatConfig.chatParameters.temperature,
            top_p: chatConfig.chatParameters.top_p,
            frequency_penalty: chatConfig.chatParameters.frequencyPenalty,
            presence_penalty: chatConfig.chatParameters.presencePenalty,
        };

        try {
            const apiResponse = await axios.post(apiEndpoint, data, { headers });
            const gptResponse = apiResponse.data.choices[0].message.content;
            setResponse(prevResponses => [...prevResponses, { question: initialPrompt, answer: gptResponse }]);
            interpretAndActOnGPTResponse(gptResponse);
        } catch (error) {
            console.error('Error with OpenAI Chat:', error.response ? error.response.data : error.message);
            alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
        }
    };

    const handleNextChunk = async () => {
        if (currentChunkIndex < chunks.length - 1) {
            const nextChunkIndex = currentChunkIndex + 1;
            setCurrentChunkIndex(nextChunkIndex);
            await processChunk(chunks[nextChunkIndex], ""); // Process the next chunk without resending the initial prompt
        }
    };

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [response]);

    const handleClearChat = () => {
        setResponse([]);
        setPrompt(''); // Clear the input field
        setChunks([]); // Clear the chunks
        setCurrentChunkIndex(0); // Reset chunk index
    };

    const handleCopyChat = () => {
        const chatContent = response.map(exchange => `You: ${exchange.question}\nGLENDA: ${exchange.answer}`).join('\n\n');
        navigator.clipboard.writeText(chatContent).then(() => {
            alert('Chat copied to clipboard');
        });
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="chat-container">
            <button className="chat-toggle-button" onClick={toggleChat}>
                {isOpen ? 'Hide Ask GLENDA' : 'Questions? Ask GLENDA'}
            </button>
            {isOpen && (
                <div className="chat-popup" ref={chatPopupRef}>
                    <div className="chat-title-bar">Glenda</div>
                    <div className={`loading-overlay ${isLoading ? 'visible' : ''}`}>
                        <div className="loading-indicator">Generating Response _</div>
                    </div>

                    <div className="response-container">
                        {response.map((exchange, index) => (
                            <div className="chat" key={index}>
                                <div className="user"><strong>You:</strong> {exchange.question}</div>
                                <div className="filebaby">
                                    <strong>GLENDA:</strong>
                                    {exchange.answer.split('\n').map((paragraph, i) => (
                                        <p key={i}>{paragraph}</p>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div ref={responseEndRef} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={prompt}
                            onChange={handleInputChange}
                            placeholder="How may I assist you, Mr. Weems?"
                        />
                        <div className="button-container">
                            <button tabIndex="0" type="submit" title="Send to GLENDA">Send</button>
                            <button type="button" onClick={handleClearChat} title="Clear Chat">Clear</button>
                            <button type="button" onClick={handleCopyChat} title="Copy Chat">Copy</button>
                            {currentChunkIndex < chunks.length - 1 && (
                                <button type="button" onClick={handleNextChunk} title="Next Chunk">Next Chunk</button>
                            )}
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
