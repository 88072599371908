import React from 'react';
import hero from './GLENDA.png'; // Ensure you have the correct path for the logo
import './App.css'; // Ensure you have the correct path for the CSS file
import Chatbot from './Chatbot'; // Import the Chatbot component

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={hero} className="hero-img" alt="GLENDA" />

            </header>
            <Chatbot setFilterCriteria={(criteria) => console.log(criteria)} />
        </div>
    );
}

export default App;
